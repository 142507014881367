.gm-style-iw-d {
  padding: 0 !important;
}

.gm-style-iw.gm-style-iw-c {
  border: none;
  box-shadow: 0px 2px 12px 0px #1018281a;
  border-radius: 24px;
  width: 250px;
  padding: 0 !important;
}
.gm-style-iw.gm-style-iw-c .gm-ui-hover-effect {
  top: 10px !important;
  right: 10px !important;
}
.gm-style-iw.gm-style-iw-c .gm-ui-hover-effect span {
  width: 20px !important;
  height: 20px !important;
}

.gm-style-iw.gm-style-iw-c .city {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 12px;
}

.gm-style-iw.gm-style-iw-c .address {
  margin-bottom: 4px;
}

.gm-style-iw.gm-style-iw-c .link {
  color: #a96fe7;

  &:hover {
    text-decoration: underline;
  }
}

.gm-style-iw-tc {
  display: none !important;
}
